import { createGlobalStyle } from "styled-components";

// Third-part css libraries
import "antd/es/style/index.css";
import "antd/es/carousel/style/index.css";
import "antd/es/modal/style/index.css";
import "antd/es/button/style/index.css";
import "antd/es/notification/style/index.css";

const GlobalStyle = createGlobalStyle`
  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  *:focus {
    outline: 0;
  }

  html {
    overflow-x: hidden;
  }

  body {
    background: #fff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  body,
  input,
  button {
    font-family: 'Raleway', sans-serif;
  }

  .hamburger {
    padding: 15px 15px;
    display: none;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    @media (max-width: 962px) {
      display: inline-block;
    }
  }
  .hamburger:hover {
    opacity: 0.7;
  }
  .hamburger.is-active:hover {
    opacity: 0.7;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000;
  }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }

  .hamburger--minus .hamburger-inner::before,
  .hamburger--minus .hamburger-inner::after {
    transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out,
      opacity 0s linear;
  }

  .hamburger--minus.is-active .hamburger-inner::before,
  .hamburger--minus.is-active .hamburger-inner::after {
    opacity: 0;
    transition: bottom 0.08s ease-out, top 0.08s ease-out,
      opacity 0s 0.08s linear;
  }

  .hamburger--minus.is-active .hamburger-inner::before {
    top: 0;
  }

  .hamburger--minus.is-active .hamburger-inner::after {
    bottom: 0;
  }

  .ant-btn {
    width: fit-content;
    height: 45px;
    background-color: #ffd800;
    border-radius: 30px;
    color: #000;
    font-weight: 500;
    font-size: 15px;
    margin-top: 25px;
    cursor: pointer;
    align-self: center;
    border: none;

    span {
      padding: 0 20px;
    }

    :hover {
      background-color: #fff;
      color: #000;
      border: 2px solid #ffd800;
    }

    :focus {
      background-color: #ffd800;
      color: #000;
    }
  }

  .ant-btn-primary {
    background-color: #ffd800;
  }

  .ant-modal-content {
    border-radius: 30px !important;
  }

  .ant-modal-close {
    margin: 0;
    width: fit-content;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f6f6;
    padding: 40px 20px;
    border-radius: 30px !important;

    h2 {
      font-weight: bold;
      color: #000;
      position: relative;
      font-size: 20px;
      text-align: center;
      width: fit-content;

      ::before {
        content: '';
        position: absolute;
        width: 95px;
        height: 6px;
        background-color: #ffd800;
        top: -15px;
        left: 35px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: 80%;

      @media (max-width: 450px) {
        width: 100%;
      }

      div {
        div {
          font-weight: bold;
        }
      }

      #anexo {
        width: 50%;
        background-color: #ffd800;
        border-radius: 30px;
        height: 45px;
        display: flex;
        justify-content: center;
        margin: 10px 0;
        cursor: pointer; 

        p {
            height: fit-content;
            margin: 12px 0;
          }

        img {
            width: 20px;
            margin-right: 20px;
          }
      }

      input {
        height: 45px;
        width: 100%;
        border: none;
        border-radius: 30px;
        background-color: #fff;
        padding: 0 20px;
        margin: 10px 0;

        :focus {
          border: 1px solid #ffe200;
        }
      }

      button {
        width: 110px;
        height: 45px;
        background-color: #07cc71;
        border-radius: 30px;
        color: #000;
        font-weight: 500;
        font-size: 16px;
        margin-top: 25px;
        cursor: pointer;
        align-self: center;

        :hover {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }

  #content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    #close {
      background-color: transparent;
      border: none;
      width: 20px;
      height: 20px;
      margin-top: 0px;

      :hover {
        color: #000;
      }
    }
  }
`;

export default GlobalStyle;
