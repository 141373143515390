/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { Carousel, notification } from "antd";
import Lightbox, { Modal, ModalGateway } from "react-images";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "react-input-mask";
// import renderHTML from "react-render-html";
// import { format } from "date-fns";
// import ptBR from "date-fns/locale/pt-BR";
import Helmet from "react-helmet";

import ademail from "../../services/ademail";
import api from "../../services/api";

import PopUp from "../../components/PopUp";
import {
  Navbar,
  Header,
  Banner,
  Find,
  Services,
  Logistics,
  AboutUs,
  Fleet,
  Blog,
  Contact,
  Office,
  Footer
} from "./styles";

import logo from "../../assets/img/logo.png";
import truck from "../../assets/img/find-truck.jpg";

import slide1 from "../../assets/img/slide1.jpg";
import slide5 from "../../assets/img/slide5.jpg";
import slide6 from "../../assets/img/slide6.jpg";
import slide7 from "../../assets/img/slide7.jpg";
import slide9 from "../../assets/img/slide9.jpg";
import slide10 from "../../assets/img/slide10.jpg";
import slide11 from "../../assets/img/slide11.jpg";
import slide12 from "../../assets/img/slide12.jpg";

import arrowRight from "../../assets/img/right-arrow.svg";
import caminhao from "../../assets/img/truck.svg";
import video from "../../assets/video/whlog.mp4";

import icoEtica from "../../assets/img/etica.svg";
import icoTransp from "../../assets/img/transparencia.jpeg";
import icoQuali from "../../assets/img/qualidade.svg";
import icoCompr from "../../assets/img/comprometimento.jpeg";

import icoInsta from "../../assets/img/instagram-logo.svg";
import icoFaceb from "../../assets/img/facebook-app-symbol.svg";
import icoCorac from "../../assets/img/heart.svg";

// import leiaMais from "../../assets/img/leia-mais.svg";


export default class Inicio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      activeMap: "ponta-grossa",
      isMenuOpen1: false,
      isMenuOpen2: false,
      blogPosts: []
    };

    this.imagesSlider = React.createRef();
    this.bannerSlider = React.createRef();
    this.nav = React.createRef();
  }

  async componentDidMount() {
    window.onscroll = function () {
      const top = window.pageYOffset;

      if (top > document.getElementById("servicos").offsetTop) {
        document.getElementById("othernavbar").classList.add("fixed");
      } else {
        document.getElementById("othernavbar").classList.remove("fixed");
      }
    };

    await api.get(`/posts?per_page=3&_embed`).then(response => {
      this.setState({ blogPosts: response.data });
    });
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (values, { resetForm }) => {
    const body = `
        <p>Nome: ${values.name}</p>
        <p>E-mail: ${values.email}</p>
        <p>Telefone: ${values.tel}</p>
        <p>Assunto: ${values.subject}</p>
        <p>Mensagem: ${values.message}</p>
      `;

    try {
      // Sucesso ao enviar
      await ademail.post("/email", {
        to: "wagner@whlog.com.br",
        from: values.email,
        as: values.name,
        subject: "Novo contato via site - WHLog",
        message: body
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      notification.success({
        message: "Contato enviado com sucesso! 🚀",
        placement: "bottomRight"
      });

      resetForm();
    } catch (error) {
      // Erro ao enviar

      notification.error({
        message: "Não foi possivel enviar o contato! 😔",
        description: "Verfique os campos e tente novamente mais tarde...",
        placement: "bottomRight"
      });

      console.log(error);
    }
  };

  render() {
    const {
      modalIsOpen,
      activeMap,
      isMenuOpen1,
      isMenuOpen2,
      // blogPosts
    } = this.state;

    const SignupSchema = Yup.object().shape({
      name: Yup.string().required("Campo nome é obrigatório"),
      email: Yup.string()
        .email("Informe um e-mail válido")
        .required("Campo e-mail é obrigatório"),
      tel: Yup.string().required("Campo telefone é obrigatório"),
      subject: Yup.string().required("Campo assunto é obrigatório"),
      message: Yup.string().required("Campo mensagem é obrigatório")
    });

    const images = [
      { id: 0, src: slide1 },
      { id: 1, src: slide5 },
      { id: 2, src: slide6 },
      { id: 3, src: slide7 },
      { id: 4, src: slide9 },
      { id: 5, src: slide10 },
      { id: 6, src: slide11 },
      { id: 7, src: slide12 }
    ];

    const imagesSettings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      autoplay: false,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: true,
      adaptiveHeight: true
    };

    const bannerSettings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: true
    };

    return (
      <>
        <Helmet>
          <title>WHLog Transporte e Logística Ltda.</title>
          <meta
            name="description"
            content="A WHLOG está a quase 10 anos no mercado, oferecendo serviços logísticos eficientes, sempre prezando pela qualidade dos seus serviços, ética, transparência e comprometimento."
          />
          <meta property="og:image" content={truck} />
        </Helmet>
        <main>
          <Header id="firtsnavbar" ref={this.nav} isMenuOpen1={isMenuOpen1}>
            <a id="logo" href="/" />
            <button
              id="menu"
              className={`hamburger hamburger--minus ${isMenuOpen1 ? "is-active" : ""
                }`}
              type="button"
              onClick={() => this.setState({ isMenuOpen1: !isMenuOpen1 })}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
            <div id="navbar">
              <nav>
                <ul>
                  <li>
                    <a
                      href="/"
                      onClick={() => this.setState({ isMenuOpen1: false })}
                    >
                      {" "}
                      HOME{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#sobrenós"
                      onClick={() => this.setState({ isMenuOpen1: false })}
                    >
                      {" "}
                      SOBRE NÓS{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#servicos"
                      onClick={() => this.setState({ isMenuOpen1: false })}
                    >
                      {" "}
                      SERVIÇOS{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#blog"
                      onClick={() => this.setState({ isMenuOpen1: false })}
                    >
                      {" "}
                      BLOG{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#contato"
                      onClick={() => this.setState({ isMenuOpen1: false })}
                    >
                      {" "}
                      CONTATO{" "}
                    </a>
                  </li>
                  <li id="form-item">
                    <form
                      id="form-login"
                      action="http://kmm.whlog.com.br/admin.cfm"
                      name="form_login"
                      target="_blank"
                      method="post"
                    >
                      <input
                        name="FORCE_DSN"
                        type="hidden"
                        value="WHLO01.WHLOG.COM.BR"
                      />
                      <input name="COD_GESTAO" type="hidden" value="90984" />
                      <input name="FORCE_SITE" type="hidden" value="whlog/" />
                      <input type="text" name="USUARIO" placeholder="login" />
                      <input type="password" name="SENHA" placeholder="senha" />
                      <button type="submit">ir</button>
                    </form>
                  </li>
                </ul>
              </nav>
            </div>
          </Header>
          <Banner>
            <div id="btns">
              <button
                id="preview-btn"
                onClick={() => this.bannerSlider.current.prev()}
              >
                <img id="preview-arrow" src={arrowRight} alt="Anterior" />
              </button>
              <button
                id="next-btn"
                onClick={() => this.bannerSlider.current.next()}
              >
                <img src={arrowRight} alt="Próximo" />
              </button>
            </div>
            <Carousel ref={this.bannerSlider} {...bannerSettings}>
              <div id="bg1">
                <p>Referência em logística</p>
                <h1>COMPROMISSO COM A QUALIDADE</h1>
                <a href="#servicos">
                  <p>CONHEÇA NOSSOS SERVIÇOS</p>
                </a>
              </div>
              <div id="bg2">
                <p>Comprometimento de anos</p>
                <h1>ENTREGA SEGURA E RÁPIDA, PARA TODO O BRASIL</h1>
                <a href="#sobrenós">
                  <p id="btn-text">CONHEÇA NOSSA HISTÓRIA</p>
                </a>
              </div>
            </Carousel>
          </Banner>
          <Find id="find">
            <div id="text">
              <p>Quer saber onde está?</p>
              <h1>AQUI VOCÊ ENCONTRA SUA CARGA</h1>
            </div>
            <div id="form-content">
              <div id="bg-img" />
              <div id="content2">
                <img src={caminhao} alt="Caminhão" />
                <form
                  action="http://kmm.whlog.com.br/admin.cfm"
                  name="form_login"
                  target="_blank"
                  method="post"
                >
                  <input
                    name="FORCE_DSN"
                    type="hidden"
                    value="WHLO01.WHLOG.COM.BR"
                  />
                  <input name="COD_GESTAO" type="hidden" value="90984" />
                  <input name="FORCE_SITE" type="hidden" value="whlog/" />
                  <label>
                    Digite seu <span>usuário:</span>
                  </label>
                  <input type="text" name="USUARIO" />
                  <label>
                    Digite sua <span>senha:</span>
                  </label>
                  <input type="password" name="SENHA" />

                  <button type="submit"> Pesquisar carga </button>
                  <a href="#contato">Solicite seu acesso</a>
                </form>
              </div>
            </div>
          </Find>
          <Navbar
            id="othernavbar"
            isMenuOpen2={isMenuOpen2}
            modalIsOpen={modalIsOpen}
          >
            <div id="navbar2">
              <a id="logo" href="/">
                <img src={logo} alt="Logo" />
              </a>
              <button
                id="menu"
                className={`hamburger hamburger--minus ${isMenuOpen2 ? "is-active" : ""
                  }`}
                type="button"
                onClick={() => this.setState({ isMenuOpen2: !isMenuOpen2 })}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
            <div id="navbar">
              <nav>
                <ul>
                  <li>
                    <a
                      href="/"
                      onClick={() => this.setState({ isMenuOpen2: false })}
                    >
                      {" "}
                      HOME{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#sobrenós"
                      onClick={() => this.setState({ isMenuOpen2: false })}
                    >
                      {" "}
                      SOBRE NÓS{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#servicos"
                      onClick={() => this.setState({ isMenuOpen2: false })}
                    >
                      {" "}
                      SERVIÇOS{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#blog"
                      onClick={() => this.setState({ isMenuOpen2: false })}
                    >
                      {" "}
                      BLOG{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#contato"
                      onClick={() => this.setState({ isMenuOpen2: false })}
                    >
                      {" "}
                      CONTATO{" "}
                    </a>
                  </li>
                  <li id="orcamento">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://api.whatsapp.com/send?phone=5515981720000&text=Ol%C3%A1!%20Gostaria%20de%20fazer%20de%20um%20or%C3%A7amento"
                      onClick={() => this.setState({ isMenuOpen2: false })}
                    >
                      {" "}
                      Solicite <span>orçamento</span>{" "}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </Navbar>
          <Services id="servicos">
            <div id="content1">
              <div id="text">
                <p>Nossos serviços</p>
                <h1>
                  <span>9</span> ANOS DE CREDIBILIDADE FAZENDO SEMPRE O MELHOR
                </h1>
              </div>
              <div className="container">
                <div id="bg">
                  <div id="details">
                    <div id="detail" />
                    <div id="detail" />
                    <div id="detail" />
                  </div>
                </div>
                <div id="description">
                  <div>
                    <h3 id="cargas"> Cargas </h3>
                    <h3 id="ponto"> ponto-a-ponto </h3>
                    <p>
                      {" "}
                      No transporte de cargas, utilizamos do método ponto a
                      ponto, onde a carga é embarcada em um veículo seguro, e
                      realiza o trajeto sem pontos intermediários, direto ao
                      destino final. Esse modelo é ideal para que o motorista
                      tenha controle total sobre o seu trajeto, reduzindo custos
                      e seu prazo de entrega.{" "}
                    </p>
                  </div>
                  <div>
                    <h3> Informação </h3>
                    <p>
                      {" "}
                      Informações corretas são a prioridade da nossa
                      transportadora. Entre o canal motorista e sede, as
                      informações são passadas em tempo real, garantindo a
                      segurança do condutor e da carga, para que o trajeto seja
                      tranquilo e se evite eventualidades.{" "}
                    </p>
                  </div>
                  <div>
                    <h3> Serviços emergênciais </h3>
                    <p>
                      {" "}
                      Nossa transportadora se responsabiliza pela saúde e
                      segurança de nossos colaboradores. Por isso, prestamos
                      serviços de emergência para todo tipo de imprevistos. Em
                      casos de ocorrências, atrasos, desvios inesperados de
                      frota, não medimos esforços para que os danos sejam
                      minimizados.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div id="content2">
              <div id="text">
                <p>Nossos serviços</p>
                <h1 id="text2">CONFIANÇA DA NOSSA PRÓPRIA FROTA</h1>
                <div id="subscription">
                  <p>
                    Cavalos Mecânicos modernos, automáticos, modelo EURO 5 e com
                    alta tecnologia embarcada. Carretas Sider Padrão, Porta
                    Contêiner e Tanque Inox.
                  </p>
                </div>
              </div>
            </div>
          </Services>
          <Logistics id="armazenagem">
            <div id="text">
              <p> Eficiência em </p>
              <h1>LOGÍSTICA</h1>
            </div>
            <div id="subtitle">
              <p>Possuímos um galpão com</p>
              <div id="number">
                <p id="n">5</p>
                <p id="m">mil</p>
                <p id="m2">
                  m<sup>2</sup>
                </p>
              </div>
              <p id="text2">para armaenagem de carga seca</p>
            </div>
          </Logistics>
          <AboutUs>
            <div id="sobrenós">
              <h1>Seja bem-vindo à nossa empresa </h1>
            </div>
            <div id="description">
              <h1>
                Uma empresa conectada, dinâmica e alinhada com o papel que temos
                na sociedade.
              </h1>
              <video id="video" controls autoPlay loop muted>
                <source src={video} type="video/mp4" />
              </video>
              <p>
                A WHLOG está a quase 10 anos no mercado, oferecendo serviços
                logísticos eficientes, sempre prezando pela qualidade dos seus
                serviços, ética, transparência e comprometimento.
              </p>
              <p>
                Trabalhamos com transporte ponto a ponto, podendo atender a
                todas as etapas, desde insumos até o produto acabado, atendendo
                de forma personalizada cada cliente, buscando nos adequar aos
                procedimentos de cada um.
              </p>
              <p>
                Transportamos os mais variados produtos, podendo distribuí-los
                em carretas Sider, Porta Contêiner e Tanque Inox. Temos uma
                frota composta por veículos novos e modernos, visando sempre o
                melhor atendimento. Os veículos são todos submetidos a revisões
                periódicas a fim de diminuir os riscos nas estradas. Temos uma
                equipe operacional disponível 24h, pronta para atender tanto aos
                motoristas quanto aos clientes.
              </p>
            </div>
            <div id="mission">
              <div id="bg-mission" />
              <div id="mission-description">
                <div>
                  <h3>Missão</h3>
                  <p>
                    Realizar o transporte de cargas de forma segura e eficiente, buscando a rentabilidade em suas operações.
                  </p>
                </div>
                <div>
                  <h3>Visão</h3>
                  <p>
                    Ser reconhecida no mercado como a melhor empresa de transporte em seu segmento.
                  </p>
                </div>
              </div>
            </div>
            <div id="values">
              <h3>Valores</h3>
              <div id="icons">
                <img src={icoEtica} alt="Ética" />
                <img src={icoTransp} alt="Transparência" />
                <img src={icoQuali} alt="Qualidade" />
                <img src={icoCompr} alt="Comprometimento" />
              </div>
            </div>

            <div id="politica-qualidade">
              <h2>Política de Qualidade</h2>
              <p>
                - Transportar com eficiência, qualidade e segurança e respeito ao meio ambiente oferecendo credibilidade aos nossos clientes.
              </p>
              <p>
                - Monitorar a eficácia de todos os seus processos, focando sempre a melhoria contínua dos mesmo nos sistemas da Qualidade, Saúde, Segurança e Meio Ambiente e atender aos requisitos legais aplicáveis, juntamente com seus                Colaboradores.
              </p>
              <p>
                - Utilizar de equipamentos modernos com o objetivo de superar as expectativas dos
                clientes.
              </p>
              - Os Colaboradores são incentivados a zelar pela conservação dos benspatrimoniais e equipamentos que estão sob sua guarda ou uso.
            </div>
          </AboutUs>
          <Fleet>
            <div id="text">
              <p> Um pouco da </p>
              <h1> NOSSA FROTA </h1>
            </div>
            <div id="slider-container">
              <button
                id="preview-btn"
                onClick={() => this.imagesSlider.current.prev()}
              >
                <img id="preview-arrow" src={arrowRight} alt="Anterior" />
              </button>
              <Carousel id="slider" ref={this.imagesSlider} {...imagesSettings}>
                {images.map(image => (
                  <div
                    key={image.id}
                    onClick={() =>
                      this.setState(state => ({
                        modalIsOpen: !state.modalIsOpen,
                        selectedIndex: image.id
                      }))
                    }
                  >
                    <img src={image.src} alt={image.id} />
                  </div>
                ))}
              </Carousel>
              <button
                id="next-btn"
                onClick={() => this.imagesSlider.current.next()}
              >
                <img src={arrowRight} alt="Próximo" />
              </button>
            </div>
          </Fleet>
          <Blog id="blog">
            <div id="text">
              <p> Nosso blog </p>
              <h1> NOTÍCIAS </h1>
            </div>
            {/* <div id="blog">
              {blogPosts.map(post => (
                <a
                  key={post.id}
                  className="news"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={post.link}
                >
                  <div className="news-img">
                    <img
                      src={
                        post.featured_media
                          ? post._embedded["wp:featuredmedia"]["0"]
                            .media_details.sizes.medium.source_url
                          : "https://www.uergs.edu.br/themes/modelo-noticias/images/outros/GD_imgSemImagem.png?classificacao=34752"
                      }
                      alt="Blog"
                    />
                    <div className="detail">
                      <p>Motorista WH</p>
                    </div>
                  </div>
                  <div className="news-description">
                    <p className="title">
                      {format(new Date(post.date), "dd'.'MM'.'yyyy'", {
                        locale: ptBR
                      })}
                    </p>
                    <div className="title">{post.title.rendered}</div>
                    <div className="description">
                      {renderHTML(post.excerpt.rendered)}
                    </div>
                  </div>
                  <div className="more">
                    <p>leia mais</p>
                    <img src={leiaMais} alt="Leia mais..." />
                  </div>
                </a>
              ))}
            </div> */}
            <div id="more-news">
              <a
                href="http://blog.whlog.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                VER MAIS NOTÍCIAS
              </a>
            </div>
          </Blog>
          <Contact id="contato">
            <div id="text">
              <p> Fale com a gente </p>
              <h1> CONTATO </h1>
              <div id="socialmedia">
                <a
                  href="https://www.instagram.com/whlog_logistics/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icoInsta} alt="Logo do Instagram" />
                </a>
                <a
                  href="https://www.facebook.com/WHLOG/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icoFaceb} alt="Logo do Instagram" />
                </a>
              </div>
              <PopUp />
              <div id="number">
                <p> Ligue para nós </p>
                <a href="tel:+554231223003">
                  <span>42 </span> 3122-3003
                </a>
                <p>ou nos envie uma mensagem </p>
              </div>
            </div>
            <div id="form">
              <Formik
                validationSchema={SignupSchema}
                initialValues={{
                  name: "",
                  email: "",
                  tel: "",
                  subject: "",
                  message: ""
                }}
                onSubmit={this.handleSubmit}
              >
                {props => (
                  <Form>
                    <div>
                      <input
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.name}
                        placeholder="Digite seu nome"
                        name="name"
                      />
                      {props.errors.name && props.touched.name ? (
                        <div>{props.errors.name}</div>
                      ) : null}
                    </div>
                    <div>
                      <input
                        type="email"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                        placeholder="Digite seu e-mail"
                        name="email"
                      />
                      {props.errors.email && props.touched.email ? (
                        <div>{props.errors.email}</div>
                      ) : null}
                    </div>
                    <div>
                      <Input
                        type="tel"
                        mask="(99) 99999-9999"
                        maskChar={null}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.tel}
                        placeholder="Digite seu telefone"
                        name="tel"
                      />
                      {props.errors.tel && props.touched.tel ? (
                        <div>{props.errors.tel}</div>
                      ) : null}
                    </div>
                    <div>
                      <input
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.subject}
                        placeholder="Digite o assunto"
                        name="subject"
                      />
                      {props.errors.subject && props.touched.subject ? (
                        <div>{props.errors.subject}</div>
                      ) : null}
                    </div>
                    <div id="message">
                      <textarea
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.message}
                        placeholder="Digite sua mensagem"
                        name="message"
                      />
                      {props.errors.message && props.touched.message ? (
                        <div>{props.errors.message}</div>
                      ) : null}
                    </div>
                    <button type="submit">Enviar</button>
                  </Form>
                )}
              </Formik>
            </div>
            <div id="bg" />
          </Contact>
          <Office>
            <div id="content1">
              <h1>SEDE e FILIAIS</h1>
              <div id="places">
                <button
                  onClick={() => this.setState({ activeMap: "ponta-grossa" })}
                  className={activeMap === "ponta-grossa" ? "is-active" : ""}
                >
                  <i />
                  <p>
                    Rua Otto Adolfo Justus, 77 - Contorno, Ponta Grossa - PR,
                    84061-500
                  </p>
                </button>
                <button
                  onClick={() => this.setState({ activeMap: "votorantim" })}
                  className={activeMap === "votorantim" ? "is-active" : ""}
                >
                  <i />
                  <p>
                    Rua Angeles Martinez Escanilha - Centro, Votorantim - SP,
                    18110-145
                  </p>
                </button>
                <button
                  onClick={() => this.setState({ activeMap: "jaguariaiva" })}
                  className={activeMap === "jaguariaiva" ? "is-active" : ""}
                >
                  <i />
                  <p>
                    Rua Matilde Pessa Mattos, 165 - Santa Cecília, Jaguariaíva -
                    PR, 18110-145
                  </p>
                </button>
                <button
                  onClick={() => this.setState({ activeMap: "itajai" })}
                  className={activeMap === "itajai" ? "is-active" : ""}
                >
                  <i />
                  <p>
                    Rodovia BR-101, nº4850-A, km 116,8 - Salseiros, Itajaí - SC,
                    88317-000
                  </p>
                </button>
                <button
                  onClick={() => this.setState({ activeMap: "porto-alegre" })}
                  className={activeMap === "porto-alegre" ? "is-active" : ""}
                >
                  <i />
                  <p>
                    Rua Cristiano Fischer, 1337 - Pretópolis, Porto Alegre - RS,
                    91410-001
                  </p>
                </button>
              </div>
            </div>
            <div id="content2">
              <div id="preloader_1">
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              {activeMap === "ponta-grossa" && (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14452.831591768949!2d-50.20558295350341!3d-25.094822899028692!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x77e1bf3188f5bac!2sWhlog%20Transportes!5e0!3m2!1spt-BR!2sbr!4v1572632378531!5m2!1spt-BR!2sbr"
                  width="100%"
                  height="630"
                  frameBorder="none"
                  title="Mapa"
                />
              )}
              {activeMap === "votorantim" && (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7315.6347023060225!2d-47.452078515899665!3d-23.53907086960272!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf61e005fa953f%3A0x7fdf5d13f7b9a2de!2sR.%20%C3%82ngeles%20Martin%C3%AAs%20Escanilha%2C%20Votorantim%20-%20SP%2C%2018110-013%2C%20Brasil!5e0!3m2!1spt-BR!2sus!4v1580129221003!5m2!1spt-BR!2sus"
                  width="100%"
                  height="630"
                  frameBorder="none"
                  title="Mapa"
                />
              )}
              {activeMap === "jaguariaiva" && (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3637.267150509748!2d-49.732211385036045!3d-24.267397784337042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c26a54fb443f1b%3A0x99ae29990d0c3f8a!2sR.%20Sen.%20Fl%C3%A1vio%20C.%20Guimar%C3%A3es%2C%2016%2C%20Jaguaria%C3%ADva%20-%20PR%2C%2084200-000%2C%20Brasil!5e0!3m2!1spt-BR!2sus!4v1582045077098!5m2!1spt-BR!2sus"
                  width="100%"
                  height="630"
                  frameBorder="none"
                  title="Mapa"
                />
              )}
              {activeMap === "itajai" && (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.610724279059!2d-48.726938984962295!3d-26.884106283139918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94df32c95283a7ed%3A0x8eb663100b63f6ef!2sBR-101%2C%204850%20-%20Salseiros%2C%20Itaja%C3%AD%20-%20SC%2C%2088311-600!5e0!3m2!1spt-BR!2sbr!4v1580216653184!5m2!1spt-BR!2sbr"
                  width="100%"
                  height="630"
                  frameBorder="none"
                  title="Mapa"
                />
              )}
              {activeMap === "porto-alegre" && (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.471247661567!2d-51.173657784884696!3d-30.05202378187954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951977faf5d5eaf7%3A0xf583c8ebc652be8d!2sR.%20Prof.%20Cristiano%20Fischer%2C%201337%20-%20Petr%C3%B3polis%2C%20Porto%20Alegre%20-%20RS%2C%2091410-250!5e0!3m2!1spt-BR!2sbr!4v1580128865657!5m2!1spt-BR!2sbr"
                  width="100%"
                  height="630"
                  frameBorder="none"
                  title="Mapa"
                />
              )}
            </div>
          </Office>
          <Footer>
            <div id="line" />
            <div id="text">
              <p id="rights"> 2019 - WH Log - Todos os direitos reservados.</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://agenciaade.com.br/"
              >
                <p>
                  {" "}
                  Feito com <img src={icoCorac} alt="Coração" /> por
                  agenciaade.com.br
                </p>
              </a>
            </div>
          </Footer>

          {/* Gateway lightbox */}
          <ModalGateway>
            {modalIsOpen && (
              <Modal
                onClose={() =>
                  this.setState(state => ({ modalIsOpen: !state.modalIsOpen }))
                }
              >
                <Lightbox
                  views={images}
                  currentIndex={this.state.selectedIndex}
                />
              </Modal>
            )}
          </ModalGateway>
        </main>
      </>
    );
  }
}
