import React, { Component } from "react";
import { Modal, Button, notification } from "antd";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "react-input-mask";
import Thumb from "../Thumb";

import ademail from "../../services/ademail";

import clip from "../../assets/img/clip.svg";
// Cheguem logo férias

export default class PopUp extends Component {
  constructor(props) {
    super(props);

    this.state = { visible: false };
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleSubmit = async (values, { resetForm }) => {
    const body = `
        <p>Nome: ${values.name}</p>
        <p>E-mail: ${values.email}</p>
        <p>Telefone: ${values.tel}</p>
    `;

    const cc = ["web@agenciaade.com.br"];
    const attach = document.getElementById("file").files[0];

    const mail = new FormData();
    mail.append("to", "wagner@whlog.com.br");
    mail.append("from", values.email);
    mail.append("as", values.name);
    mail.append("cc", JSON.stringify(cc));
    mail.append("subject", "Envio de currículo pelo site - WHLog");
    mail.append("message", body);
    if (attach) mail.append("attach", attach);

    const config = {
      headers: {
        "Access-Control-Allow-Headers": "*",
        "content-type": "multipart/form-data"
      }
    };
    try {
      await ademail.post("/email", mail, config);
      notification.success({
        message: "Contato enviado com sucesso! 🚀",
        placement: "bottomRight"
      });

      resetForm();
    } catch (error) {
      notification.error({
        message: "Não foi possivel enviar o contato! 😔",
        description: "Verfique os campos e tente novamente mais tarde...",
        placement: "bottomRight"
      });

      console.log(error);
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const SignupSchema = Yup.object().shape({
      name: Yup.string().required("Campo nome é obrigatório"),
      email: Yup.string()
        .email("Informe um e-mail válido")
        .required("Campo e-mail é obrigatório"),
      tel: Yup.string().required("Campo telefone é obrigatório"),
      file: Yup.mixed().required("Escolha um arquivo")
    });

    return (
      <div>
        <Button type="primary" onClick={this.showModal}>
          Trabalhe conosco
        </Button>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <h2 id="title">Trabalhe conosco</h2>
          <Formik
            initialValues={{
              name: "",
              email: "",
              tel: "",
              file: null
            }}
            validationSchema={SignupSchema}
            onSubmit={this.handleSubmit}
          >
            {props => (
              <Form>
                <div>
                  <input
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.name}
                    placeholder="Digite seu nome"
                    name="name"
                  />
                  {props.errors.name && props.touched.name ? (
                    <div>{props.errors.name}</div>
                  ) : null}
                </div>
                <div>
                  <input
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                    placeholder="Digite seu e-mail"
                    name="email"
                  />
                  {props.errors.email && props.touched.email ? (
                    <div>{props.errors.email}</div>
                  ) : null}
                </div>
                <div>
                  <Input
                    type="tel"
                    mask="(99) 99999-9999"
                    maskChar={null}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.tel}
                    placeholder="Digite seu telefone"
                    name="tel"
                  />
                  {props.errors.tel && props.touched.tel ? (
                    <div>{props.errors.tel}</div>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="file" id="anexo">
                    <img src={clip} alt="Clip" />
                    <p>Anexar currículo</p>
                  </label>
                  <input
                    id="file"
                    hidden
                    name="file"
                    type="file"
                    onChange={event => {
                      props.setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    className="form-control"
                    multiple="multiple"
                  />
                  <Thumb
                    file={props.values.file}
                    clearField={() => props.setFieldValue("file", null)}
                  />
                  {props.errors.file && props.touched.file ? (
                    <div>{props.errors.file}</div>
                  ) : null}
                </div>
                <button type="submit">Enviar</button>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    );
  }
}
