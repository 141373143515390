import React, { Component } from "react";
import { IoMdClose } from "react-icons/io";

export default class Thumb extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      thumb: undefined
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({ loading: true }, () => {
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file, clearField } = this.props;
    const { loading, thumb } = this.state;

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return (
      <div id="content">
        <img src={thumb} alt={file.name} className="img-thumbnail mt-2" />
        <button id="close" type="button" onClick={clearField}>
          <IoMdClose />
        </button>
      </div>
    );
  }
}
