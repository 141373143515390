import React from "react";
import { Router } from "react-router-dom";

import Routes from "./routes";
import history from "./services/history";
import GlobalStyle from "./styles/global";
// import { logPageView } from "./utils/googleAnalytics";

// logPageView();

export default function App() {
  return (
    <Router history={history}>
      <GlobalStyle />
      <Routes />
    </Router>
  );
}
