import styled, { keyframes } from "styled-components";

import logo from "../../assets/img/logo.png";
import logoBranca from "../../assets/img/logo-branca.svg";

import banner2 from "../../assets/img/banner2.jpg";
import bg2 from "../../assets/img/bg2.jpg";
import truck from "../../assets/img/find-truck.jpg";
import bgServicos from "../../assets/img/bg-servicos.jpg";
import bgLogistics from "../../assets/img/bg-logistics.jpg";
import bgAbout from "../../assets/img/bg-aboutus.jpg";
import bgMissao from "../../assets/img/bg-missao.jpg";
import bgContato from "../../assets/img/bg-contato.jpg";
import placeholder from "../../assets/img/placeholder-yellow.svg";
import placeholderBlack from "../../assets/img/placeholder-black.svg";

export const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: ${props => (props.isMenuOpen1 ? "fixed" : "absolute")};
  width: 100%;
  height: 98px;
  z-index: 999;
  padding-top: 35px;
  background-color: ${props => (props.isMenuOpen1 ? "#fff" : "none")};
  transition: all ease;
  transition-delay: 0.2s;

  @media (max-width: 1047px) {
    justify-content: center;
  }
  @media (max-width: 962px) {
    justify-content: space-between;
    padding-right: ${props => (props.isMenuOpen1 ? "40px" : "20px")};
  }

  #menu {
    display: none;

    @media (max-width: 962px) {
      display: inline-flex;
    }
  }

  #logo {
    display: flex;
    justify-content: center;
    width: 350px;
    height: 60px;
    background-image: url(${props => (props.isMenuOpen1 ? logo : logoBranca)});
    background-size: 160px;
    background-repeat: no-repeat;
    background-position: center;
    transition: all ease;
    transition-delay: 0.2s;

    @media (max-width: 1047px) {
      padding-left: 4%;
    }

    @media (max-width: 962px) {
      margin-left: 40px;
      background-position: left;
    }

    @media (max-width: 550px) {
      background-size: 120px;
      height: 50px;
    }

    img {
      width: 160px;
    }
  }

  #navbar {
    display: flex;
    width: 64%;
    border-bottom: 0.8px solid rgba(255, 255, 255, 0.3);

    @media (max-width: 1047px) {
      width: inherit;
    }

    @media (max-width: 962px) {
      transition: all ease 0.5s;
      top: ${props => (props.isMenuOpen1 ? "98px" : "-1200px")};
      position: ${props => (props.isMenuOpen1 ? "fixed" : "absolute")};
      background-color: #fff;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 35px 0;
      z-index: 99;
    }

    #form-item {
      &:hover::after {
        display: none !important;
      }
    }
    #form-login {
      input {
        border: 0;
        background: #fff;
        border-radius: 30px;
        padding: 5px;
        margin: 0 5px;
        color: #000;
        max-width: 100px;
      }
      button {
        border: 0;
        border-radius: 30px;
        background-color: #07cc71;
        color: #000;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        padding: 5px 12px;
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;
    padding-left: 30px;
    height: 64px;

    @media (max-width: 962px) {
      flex-direction: column;
      padding-left: 0px;
      height: fit-content;
    }
  }

  li {
    padding: 20px;
    display: flex;
    flex-direction: column;

    :hover::after {
      content: "";
      width: 100%;
      height: 3px;
      background-color: #fff;
      position: relative;
      top: 21.5px;
      transition: all ease 0.5s;
    }

    @media (max-width: 962px) {
      :hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }
    }

    a {
      color: #fff;
      font-weight: bold;

      @media (max-width: 962px) {
        color: #000;
      }
    }
  }
`;

export const Banner = styled.section`
  height: 760px;
  width: 100%;
  position: relative;
  z-index: 0;
  margin-bottom: 70px;
  display: flex;

  .ant-carousel,
  .slick-slider,
  .slick-list,
  .slick-slide {
    width: 100%;
    height: 760px;
    position: relative;
  }

  #bg1 {
    background: url(${banner2}) no-repeat;
    background-position: 10% center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.2);
    padding-left: 15.625%;
    width: 100%;
    height: 760px;
    padding-top: 325px;

    @media (max-width: 1240px) {
      padding-right: 15.625%;
    }

    @media (max-width: 515px) {
      padding: 325px 30px;
    }
  }

  #bg2 {
    background: url(${bg2}) no-repeat;
    background-position: top center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.5);
    padding-left: 15.625%;
    width: 100%;
    height: 760px;
    padding-top: 325px;

    @media (max-width: 1240px) {
      padding-right: 15.625%;
    }

    @media (max-width: 515px) {
      padding: 325px 30px;
    }

    @media (max-width: 330px) {
      padding: 240px 30px;
    }

    h1 {
      max-width: 850px;
    }
  }

  p {
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    position: relative;
    margin-top: 15px;
    margin-left: 85px;

    ::before {
      content: "";
      position: absolute;
      width: 70px;
      height: 8px;
      top: 8px;
      background-color: #ffd800;
      left: -85px;
    }
    @media (max-width: 330px) {
      font-size: 15px;
    }
  }

  h1 {
    font-size: 60px;
    font-weight: 900;
    color: #fff;
    max-width: 555px;
    line-height: 60px;
    text-align: start;

    @media (max-width: 900px) {
      font-size: 55px;
      line-height: 55px;
    }

    @media (max-width: 850px) {
      font-size: 50px;
      line-height: 50px;
    }

    @media (max-width: 650px) {
      font-size: 40px;
      line-height: 40px;
    }

    @media (max-width: 515px) {
      font-size: 38px;
      line-height: 38px;
    }

    @media (max-width: 400px) {
      font-size: 35px;
      line-height: 35px;
    }
  }

  a {
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 265px;
      height: 45px;
      background-color: #ffe200;
      color: #000;
      border-radius: 40px;
      font-size: 14px;

      @media (max-width: 620px) {
        margin: auto;
      }

      ::before {
        display: none;
      }
    }
  }

  #btns {
    position: absolute;
    z-index: 1;
    display: flex;
    right: 200px;
    margin-top: 430px;

    @media (max-width: 1240px) {
      width: 100%;
      justify-content: space-between;
      right: 0;
    }

    @media (max-width: 650px) {
      position: absolute;
      margin-top: 590px;
      justify-content: center;
    }

    button {
      width: 40px;
      height: 40px;
      background-color: rgb(255, 255, 255, 0.7);
      border: none;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 22.5px;

      @media (max-width: 1240px) {
        margin: 22.5px 40px;
      }

      img {
        width: 10px;
        height: 20px;
      }

      #preview-arrow {
        transform: rotate(180deg);
      }
    }
  }
`;

export const Find = styled.div`
  margin-bottom: 150px;

  #text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    p {
      font-weight: bold;
      font-size: 16px;
      color: #000;
      position: relative;
      margin-top: 15px;

      ::before {
        content: '';
        position: absolute;
        width: 95px;
        height: 6px;
        background-color: #ffd800;
        top: -15px;
        left: 23.165px;
      }
    }

    h1 {
      font-size: 45px;
      font-weight: 900;
      color: #000;
      max-width: 500px;
      line-height: 45px;
      text-align: center;

      @media (max-width: 550px) {
        font-size: 25px;
        line-height: 25px;
        max-width: 310px;
      }
    }
  }

  #form-content {
    display: flex;
    align-items: center;

    #bg-img {
      width: 53.2%;
      height: 326px;
      background: url(${truck}) no-repeat;
      background-size: cover;
      background-position: center;

      @media(max-width: 700px) {
        display: none;
      }
    }
  }

  #content2 {
    position: relative;
    width: 575px;
    max-width: 555px;
    height: 440px;
    background-color: #ffe200;
    justify-content: center;
    align-items: center;
    display: flex;

    @media(max-width: 700px) {
        width: 100%;
        margin: auto;
      }

    img {
      width: 285px;
      left: 24.3%;
      position: absolute;
      z-index: 1;
      top: -60px;

      @media(max-width: 700px) {
        left: initial;
      }
    }
  }

    form {
      margin-top: 60px;
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 0;
      height: 350px;
      width: 365px;
      padding: 0 20px;


      label {
        font-size: 14px;
        color: #000;
        margin-bottom: 10px;

        span {
          font-weight: bold;
        }
      }

      input {
        max-width: 320px;
        height: 50px;
        background-color: #fff;
        border-radius: 39px;
        border: none;
        margin-bottom: 20px;
        padding-left: 20px;
      }

      button {
        width: 190px;
        height: 50px;
        border-radius: 30px;
        background-color: #07CC71;
        color: #000;
        font-size: 16px;
        font-weight: bold;
        margin: 10px auto;
        cursor: pointer;
      }

      a {
        color: #000;
        text-decoration: underline;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
`;

export const Navbar = styled.div`
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-top: ${props => (props.isMenuOpen2 ? "10px" : "35px")};
  background-color: #fff;
  transition: all ease 0.5s;
  padding-bottom: ${props => (props.isMenuOpen2 ? "10px" : "30px")};
  position: ${props => (props.isMenuOpen2 ? "fixed" : "absolute")};
  z-index: ${props => (props.isMenuOpen2 ? "9999" : "99")};
  top: ${props => (props.isMenuOpen2 ? "0px" : "unset")};

  &.fixed {
    position: fixed;
    top: 0;
    padding: 10px 0;
    transition: all ease 0.5s;
    z-index: ${props => (props.modalIsOpen ? "-1" : "99")};

    @media (max-width: 1015px) {
      padding-right: 20px;
      height: 80px;
    }
  }

  @media (max-width: 1119px) {
    justify-content: center;
  }
  @media (max-width: 1015px) {
    justify-content: space-between;
    padding-right: ${props => (props.isMenuOpen1 ? "40px" : "20px")};
    flex-direction: column;
    height: 125px;
  }

  #menu {
    display: none;

    @media (max-width: 1015px) {
      display: inline-block !important;
    }
  }

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: #000 !important;
  }

  #logo {
    display: flex;
    justify-content: center;
    padding-left: 4.86%;
    padding-right: 9.7%;

    @media (max-width: 1119px) {
      padding-left: 4%;
    }

    @media (max-width: 1015px) {
      padding-left: 40px;
    }

    img {
      width: 160px;

      @media (max-width: 550px) {
        width: 120px;
        height: 45px;
        align-self: center;
        margin-top: 10px;
      }
    }
  }

  #navbar2 {
    width: 36%;
    display: flex;
    justify-content: center;
    transition: all ease 0.5s;

    @media (max-width: 1015px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  #navbar {
    display: flex;
    width: 64%;
    border-bottom: 0.8px solid rgba(0, 0, 0, 0.3);
    z-index: 99;
    transition: all ease 0.5s;

    @media (max-width: 1119px) {
      width: inherit;
    }

    @media (max-width: 1015px) {
      top: ${props => (props.isMenuOpen2 ? "80px" : "-5000px")};
      position: ${props => (props.isMenuOpen2 ? "fixed" : "absolute")};
      background-color: #fff;
      width: 110%;
      justify-content: center;
      align-items: center;
      padding: 35px 0 35px 10%;
      z-index: 99;
    }
  }

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;
    padding-left: 30px;
    height: 64px;

    @media (max-width: 1015px) {
      flex-direction: column;
      padding-left: 0px;
      height: fit-content;
    }
  }

  li {
    padding: 20px;
    display: flex;
    flex-direction: column;

    :hover::after {
      content: "";
      width: 100%;
      height: 3px;
      background-color: #000;
      position: relative;
      top: 21.5px;
    }

    @media (max-width: 1015px) {
      :hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }

      :last-child {
        border: none;
      }
    }

    a {
      color: #000;
      font-weight: bold;
    }
  }

  #orcamento {
    position: relative;
    margin: 0;
    padding: 0px;

    @media (max-width: 1015px) {
      margin-top: 10px;
    }

    a {
      font-weight: normal;
      margin: 0 15px;
      z-index: 1;
      padding: 20px;

      span {
        font-weight: bold;
      }
    }

    ::after {
      content: "";
      z-index: 0;
      position: absolute;
      background-color: #ffe200;
      width: 100%;
      height: 50px;
      padding: 10px;
      top: 6px;
      border-radius: 30px;
      left: 0;
    }
  }
`;

export const Services = styled.div`
  #content1 {
    background-color: #e6e6e6;
    padding-top: 260px;
    padding-bottom: 220px;
    height: fit-content;

    @media (max-width: 550px) {
      padding-top: 210px;
      padding-bottom: 170px;
    }
  }

  #text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    @media (max-width: 800px) {
      margin-bottom: 50px;
    }

    p {
      font-weight: bold;
      font-size: 16px;
      color: #000;
      position: relative;
      margin-top: 15px;

      ::before {
        content: "";
        position: absolute;
        width: 95px;
        height: 6px;
        background-color: #ffd800;
        top: -15px;
        left: 13.69px;
      }
    }

    h1 {
      font-size: 45px;
      font-weight: 900;
      color: #000;
      max-width: 690px;
      line-height: 45px;
      text-align: center;
      padding: 0 20px;

      span {
        font-weight: 900;
      }

      @media (max-width: 550px) {
        font-size: 25px;
        line-height: 25px;
        padding: 0 20px;
        width: 100%;
      }
    }
  }

  .container {
    display: flex;
    position: relative;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
    }
  }

  #details {
    height: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    right: -48px;
    width: 96px;

    #detail {
      width: 100%;
      height: 96px;
      background-color: #ffe200;

    }
  }

  #bg {
    background: url(${bgServicos}) no-repeat;
    background-size: cover;
    background-position: right;
    width: 37.8%;
    height: 480px;
    position: relative;

    @media (max-width: 800px) {
      width: 100%;
      margin-top: 100px;
    }

    @media (max-width: 560px) {
      overflow: hidden;
    }

    ::before {
      content: "";
      position: absolute;
      z-index: 0;
      width: 300px;
      height: 70px;
      background-color: #fff;
      bottom: 100%;
      right: 70px;
    }

    ::after {
      content: "";
      position: absolute;
      z-index: 0;
      width: 300px;
      height: 70px;
      background-color: #fff;
      top: 100%;
      right: 70px;
    }
  }

  #description {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 80px;

    @media (max-width: 800px) {
      width: 90%;
      margin: auto;
    }

    h3 {
      width: 130px;
      font-weight: 900;
      line-height: 24px;
      font-size: 18px;
    }

    p {
      font-size: 14px;
      max-width: 660px;
      padding-right: 50px;

      @media (max-width: 800px) {
        width: 100%;
        padding-right: 0px;
      }
    }

    #cargas {
      margin-bottom: 0;
    }

    #ponto {
      width: 190px;
    }
  }
  #content2 {
    padding-top: 90px;
    padding-bottom: 85px;

    #subtitle {
      width: fit-content;
      display: flex;
      align-items: center;

      h1 {
        font-size: 200px;
        color: #000;
        font-weight: 900;
        line-height: 200px;
        letter-spacing: -20px;
        margin-bottom: 0;
        margin-right: 20px;

        @media (max-width: 550px) {
          font-size: 130px;
          margin-right: 0px;
          padding: 0 25px 0 5px;
          line-height: 130px;
        }
      }

      p {
        font-size: 30px;
        font-weight: bold;
        margin: 0;

        @media (max-width: 550px) {
          font-size: 20px;
        }

        ::before {
          display: none;
        }
      }

      #more {
        @media (max-width: 550px) {
          width: -webkit-fill-available;
        }
      }
    }

    #text2 {
      @media (max-width: 550px) {
        font-size: 25px;
        line-height: 25px;
        padding: 0 20px;
        max-width: 350px;
      }
    }

    #subscription {
      margin-top: 15px;
      max-width: 595px;
      height: fit-content;
      background-color: #ffe200;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 32.5px 40px;

      @media (max-width: 550px) {
        padding: 32.5px 20px;
      }

      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;

        ::before {
          display: none;
        }
      }
    }
  }
`;

export const Logistics = styled.div`
  display: none;
  background: url(${bgLogistics}) no-repeat;
  background-position: top center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  padding: 140px 0 180px 0;
  margin-bottom: 220px;

  #text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    p {
      font-weight: bold;
      font-size: 16px;
      color: #fff;
      position: relative;
      margin-top: 15px;

      ::before {
        content: "";
        position: absolute;
        width: 95px;
        height: 6px;
        background-color: #ffd800;
        top: -15px;
        left: 3.205px;
      }
    }

    h1 {
      font-size: 45px;
      font-weight: 900;
      color: #fff;
      max-width: 650px;
      line-height: 45px;
      text-align: center;
    }
  }

  #subtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 30px;
      font-weight: bold;
      margin: 0;
      color: #fff;

      ::before {
        display: none;
      }
    }
  }

  #number {
    display: flex;
    height: 140px;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    #n {
      font-size: 200px;
      color: #fff;
      font-weight: 900;
    }

    #m {
      font-size: 110px;
      font-weight: 900;
      color: #fff;
      margin-bottom: 0;
      margin-top: 50px;
    }

    #m2 {
      font-size: 70px;
      font-weight: 900;
      color: #fff;
      margin-bottom: 0;
      margin-top: 75px;
    }
  }

  #text2 {
    max-width: 330px;
    text-align: center;
  }
`;

export const AboutUs = styled.div`
  margin-top: -220px;
  padding-top: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;

  @media (max-width: 550px) {
    margin-top: none;
    padding-top: 170px;
    margin-bottom: 130px;
  }

  #sobrenós {
    background: url(${bgAbout}) no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: overlay;
    height: 450px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      color: #fff;
      font-size: 45px;
      font-weight: 900;
      max-width: 385px;
      text-align: center;
      line-height: 45px;

      @media (max-width: 550px) {
        font-size: 25px;
        line-height: 25px;
      }
    }
  }

  #description {
    max-width: 845px;
    background-color: #ffe200;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 280px;

    @media (max-width: 600px) {
      padding-bottom: 30px;
    }

    h1 {
      color: #000;
      font-size: 45px;
      font-weight: 900;
      text-align: center;
      line-height: 45px;
      padding: 0 55px;
      margin-bottom: 60px;
      margin-top: 60px;

      @media (max-width: 550px) {
        font-size: 25px;
        line-height: 25px;
        padding: 0 20px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
      padding: 0 11.8%;
    }

    #video {
      width: 76.4%;
      margin-bottom: 60px;

      @media (max-width: 500px) {
        width: 90%;
      }
    }
  }

  #mission {
    width: 100%;
    display: flex;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    #bg-mission {
      background: url(${bgMissao}) no-repeat;
      background-size: cover;
      background-position: center;
      width: 47.36%;
      height: 436px;
      position: relative;
      top: -95px;

      @media (max-width: 600px) {
        width: 100%;
        top: 0px;
      }
    }

    #mission-description {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-left: 60px;
      padding-top: 75px;

      @media (max-width: 600px) {
        width: 100%;
        padding-top: 0;
        margin-top: 30px;
      }

      h3 {
        font-size: 20px;
        font-weight: 900;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 30px;
        max-width: 385px;

        @media (max-width: 600px) {
          max-width: 90%;
        }
      }
    }
  }

  #values {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 560px) {
      margin-top: 50px;
    }

    h3 {
      font-size: 20px;
      font-weight: 900;
    }

    #icons {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: center;

      img {
        height: 145px;
        margin: 15px;
      }
    }
  }

  #politica-qualidade{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 75%;

    @media (max-width: 560px) {
      width: 80%;
    }

    h2{
      font-weight: 900;
      padding-top: 2px;
      border-top: #ffe200 4px solid;
      margin: 40px 0;
    }
  }
`;

export const Fleet = styled.div`
  max-width: 986px;
  margin: auto;
  margin-bottom: 230px;
  /* height: fit-content; */

  @media (max-width: 550px) {
    margin-bottom: 100px;
  }

  #text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    @media (max-width: 550px) {
      margin-bottom: 70px;
    }

    p {
      font-weight: bold;
      font-size: 16px;
      color: #000;
      position: relative;
      margin-top: 15px;

      ::before {
        content: "";
        position: absolute;
        width: 95px;
        height: 6px;
        background-color: #ffd800;
        top: -15px;
        left: 3.205px;
      }
    }

    h1 {
      font-size: 45px;
      font-weight: 900;
      color: #000;
      max-width: 650px;
      line-height: 45px;
      text-align: center;

      @media (max-width: 550px) {
        font-size: 25px;
        line-height: 25px;
      }
    }
  }

  #slider-container {
    width: 100%;
    height: fit-content;
  }

  .slick-slide {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all ease-in-out 0.5s;
      cursor: pointer;

      :hover {
        filter: brightness(75%);
      }
    }
  }

  #slider-container {
    position: relative;
    height: 100%;
  }

  button {
    position: absolute;
    z-index: 1;
    width: 40px;
    height: 40px;
    background-color: rgb(255, 255, 255, 0.7);
    border: none;
    border-radius: 100%;
    top: 45.8%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 10px;
      height: 20px;
    }

    #preview-arrow {
      transform: rotate(180deg);
    }
  }

  #preview-btn {
    left: 7%;
  }

  #next-btn {
    right: 7%;
  }
`;

export const Blog = styled.div`
  height: fit-content;

  #text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    height: 550px;

    @media (max-width: 550px) {
      height: 350px;
    }

    p {
      font-weight: bold;
      font-size: 16px;
      color: #000;
      position: relative;
      margin-top: 15px;

      ::before {
        content: "";
        position: absolute;
        width: 95px;
        height: 6px;
        background-color: #ffd800;
        top: -15px;
        left: -4.35px;
      }
    }

    h1 {
      font-size: 45px;
      font-weight: 900;
      color: #000;
      max-width: 650px;
      line-height: 45px;
      text-align: center;

      @media (max-width: 550px) {
        font-size: 25px;
        line-height: 25px;
      }
    }
  }

  #blog {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    top: -200px;
    flex-wrap: wrap;

    @media (max-width: 550px) {
      top: -100px;
    }
  }

  .news {
    max-width: 320px;
    margin: 5px;
    color: #000;
    margin-top: 50px;

    :hover {
      filter: brightness(120%);
    }
  }

  .news-img {
    width: 320px;
    height: 405px;
    position: relative;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }

    .detail {
      position: absolute;
      width: 210px;
      height: 45px;
      background-color: #ffe200;
      color: #000;
      font-size: 14px;
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      left: 55px;

      p {
        margin: 0;
      }
    }
  }

  .news-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 230px;
    margin: auto;
    margin-top: 25px;

    .title {
      font-size: 22px;
      font-weight: 900;
      line-height: 20px;
      color: #000;

      @media (max-width: 550px) {
        font-size: 18px;
        font-weight: 900;
        line-height: 18px;
      }
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 40px;
    }
  }

  .more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #000;
    font-weight: 900;
    position: relative;

    p {
      margin: 0;
      margin-right: 15px;
    }

    ::after {
      content: "";
      position: absolute;
      width: 200px;
      height: 8px;
      background-color: #ffd800;
      bottom: -20px;
      left: 60px;
    }
  }

  #more-news {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -100px;
    width: 210px;
    height: 45px;
    background-color: #fff;
    margin: auto;
    border-radius: 40px;

    @media (max-width: 550px) {
      top: -50px;
    }

    :hover {
      background-color: #ffe200;
    }

    a {
      color: #000;
      font-weight: 900;
    }
  }
`;

export const Contact = styled.div`
  background: url(${bgContato}) no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
  background-position: center;
  padding-top: 100px;
  position: relative;
  z-index: 0;
  padding-bottom: 145px;
  bottom: -3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-weight: bold;
      font-size: 16px;
      color: #fff;
      position: relative;
      margin-top: 15px;

      ::before {
        content: "";
        position: absolute;
        width: 95px;
        height: 6px;
        background-color: #ffd800;
        top: -15px;
        left: 17.875px;
      }
    }

    h1 {
      font-size: 45px;
      font-weight: 900;
      color: #fff;
      max-width: 650px;
      line-height: 45px;
      text-align: center;

      @media (max-width: 550px) {
        font-size: 25px;
        line-height: 25px;
        padding: 0 20px;
        width: 100%;
      }
    }

    #number {
      margin-top: 60px;

      p {
        text-align: center;

        ::before {
          display: none;
        }
      }

      a {
        font-size: 55px;
        font-weight: bold;
        margin: 0;
        color: #fff;

        @media (max-width: 550px) {
          font-size: 40px;
        }

        span {
          font-size: 45px;

          @media (max-width: 550px) {
            font-size: 25px;
          }
        }
      }
    }
  }

  #form {
    height: fit-content;
    padding: 60px 0px;
    background-color: #f6f6f6;
    border-radius: 39px;
    max-width: 815px;
    margin: auto;
    display: flex;
    align-items: center;
    margin-top: 15px;
    position: relative;
    z-index: 2;
    margin: 0 20px;

    @media (max-width: 500px) {
      padding: 40px 0px;
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    div {
      width: 41%;

      @media (max-width: 500px) {
        width: 100%;
        margin: 15px 6%;
      }

      input {
        height: 45px;
        width: 100%;
        border: none;
        border-radius: 30px;
        background-color: #fff;
        padding: 20px;
        margin: 20px 0px 10px 0px;

        @media (max-width: 500px) {
          width: 100%;
          margin: 0;
        }

        :focus {
          border: 1px solid #ffe200;
        }
      }

      div {
        width: 100%;
        font-weight: bold;
      }
    }

    #message {
      width: 100%;
      margin: 20px 6%;

      textarea {
        height: 145px;
        width: 100%;
        color: #000;
        border: none;
        border-radius: 30px;
        background-color: #fff;
        padding: 20px;
      }
    }

    button {
      width: 110px;
      height: 45px;
      background-color: #07cc71;
      border-radius: 30px;
      color: #000;
      font-weight: 500;
      font-size: 16px;
      margin-top: 25px;
      cursor: pointer;

      :hover {
        background-color: #000;
        color: #fff;
      }
    }
  }

  #bg {
    height: 375px;
    width: 110%;
    background-color: #000;
    position: absolute;
    z-index: 1;
    bottom: 0;
    border-top-left-radius: 90%;
    border-top-right-radius: 90%;

    @media (max-width: 560px) {
      display: none;
    }
  }

  #socialmedia {
    a {
      padding: 0 20px;
    }

    img {
      width: auto;
      height: 30px;
      position: relative;
      z-index: 99;
    }
  }
`;

export const Office = styled.div`
  background-color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  bottom: -2px;

  @media (max-width: 850px) {
    flex-direction: column;
  }

  #content1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 850px) {
      width: 100%;
    }
  }

  #content2 {
    width: 50%;
    border-radius: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 850px) {
      width: 100%;
    }
  }

  #preloader_1 {
    position: absolute;
    width: 65px;
    height: 50px;
  }
  #preloader_1 span {
    display: block;
    bottom: 0px;
    width: 9px;
    height: 5px;
    background: #ffe200;
    position: absolute;
    animation: preloader_1 1.5s infinite ease-in-out;
  }

  #preloader_1 span:nth-child(2) {
    left: 11px;
    animation-delay: 0.2s;
  }
  #preloader_1 span:nth-child(3) {
    left: 22px;
    animation-delay: 0.4s;
  }
  #preloader_1 span:nth-child(4) {
    left: 33px;
    animation-delay: 0.6s;
  }
  #preloader_1 span:nth-child(5) {
    left: 44px;
    animation-delay: 0.8s;
  }
  @keyframes preloader_1 {
    0% {
      height: 5px;
      transform: translateY(0px);
      background: #ffe200;
    }
    25% {
      height: 30px;
      transform: translateY(15px);
      background: #ffe200;
    }
    50% {
      height: 5px;
      transform: translateY(0px);
      background: #ffe200;
    }
    100% {
      height: 5px;
      transform: translateY(0px);
      background: #ffe200;
    }
  }

  iframe {
    z-index: 1;
  }

  h1 {
    font-size: 45px;
    font-weight: 900;
    color: #fff;
    width: fit-content;

    @media (max-width: 550px) {
      font-size: 25px;
      line-height: 25px;
      margin: 0.5em auto;
    }
  }

  #places {
    display: flex;
    max-width: 660px;
    flex-wrap: wrap;
    margin-bottom: 40px;

    @media (max-width: 1319px) {
      justify-content: center;
    }
  }

  button {
    width: 320px;
    height: fit-content;
    background-color: #000;
    border: none;
    color: #fff;
    border-radius: 9px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 9px 10px;
    margin: 5px;
    cursor: pointer;

    p {
      margin: 0;
      text-align: start;
      width: 85%;
    }

    i {
      background-image: url(${placeholder});
      background-size: cover;
      width: 30px;
      height: 38px;
      margin-right: 15px;
    }
  }

  .is-active {
    background-color: #ffe200;
    color: #000;
    i {
      background-image: url(${placeholderBlack});
    }
  }
`;

const heartBeat = keyframes`
    0%, 100%, 40%, 60% {
        transform: scale(1);
    }
    20%, 80% {
        transform: scale(1.3);
    }
`;

export const Footer = styled.footer`
  height: fit-content;
  width: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;

  #line {
    width: 60.4%;
    height: 1px;
    background-color: #ffe200;
  }

  #text {
    display: flex;
    padding-top: 25px;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 650px) {
      flex-direction: column;
      padding-bottom: 15px;
    }
  }

  p {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    width: fit-content;
  }

  img {
    margin: 0 3px;
    height: 15px;

    animation-name: ${heartBeat};
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
`;
